<template>
	<div class="home">
		<div class="home_top">
			<div style="width: 100%; height: 300px">
				<div v-if="img_url">
					<img class="home_top_img" v-if="img_url == '../../assets/bg.svg'" src="../../assets/bg.svg" type="image/svg+xml" />
					<img class="home_top_img" v-else :src="img_url" type="image/svg+xml" />
				</div>
			</div>
			<div :style="!check_root.title ? 'margin-top: -290px' : ''" class="home_top_search">
				<div class="home_top_p_div">{{ check_root.title || '' }}</div>
				<div :style="!check_root.title ? 'height: 70px' : ''" class="home_top_search_box">
					<div class="home_top_search_box_div">
						<el-input v-model="journal_name" size="large" placeholder="请输入期刊名称查询"> </el-input>
						<el-button @click="search_btn" type="primary">搜 索</el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="home_bot">
			<div v-if="journal_folder.length != 0" class="home_bot_one">
				<div class="home_bot_l">
					<el-menu style="height: 100%" default-active="1001" class="el-menu-vertical-demo">
						<el-menu-item @click="cut_btn({ name: '全部', id: -1 })" class="sub-menu" index="1001">
							<el-icon><icon-menu /></el-icon>
							<span>全部</span>
						</el-menu-item>
						<template v-for="(e, i) in journal_folder" :key="i">
							<el-sub-menu @click="cut_btn(e)" v-if="e.childlist.length != 0" class="sub-menu" :index="i">
								<template #title>
									<el-icon>
										<location />
									</el-icon>
									<span>{{ e.name }}</span>
								</template>
								<el-menu-item-group title="">
									<template v-for="(e1, i1) in e.childlist" :key="i1">
										<el-sub-menu @click="cut_btn(e1)" v-if="e1.childlist.length != 0" :index="i + '-' + i1">
											<template #title>
												<el-icon style="width: 35px">
													<location />
												</el-icon>
												<span>{{ e1.name }}</span>
											</template>
											<!-- <el-menu-item index="1-4-1">浙江模具8</el-menu-item>
											<el-sub-menu index="1-4-2">
												<template #title>
													<el-icon style="width: 55px"><location /></el-icon>
													<span>浙江模具</span>
												</template>
												<el-menu-item index="1-4-2-1">浙江模具8</el-menu-item>
											</el-sub-menu> -->
										</el-sub-menu>
										<el-menu-item @click="cut_btn(e1)" v-else :index="i + '-' + i1">{{ e1.name }}</el-menu-item>
									</template>
								</el-menu-item-group>
							</el-sub-menu>
							<el-menu-item @click="cut_btn(e)" v-else class="sub-menu" :index="i">
								<el-icon><icon-menu /></el-icon>
								<span>{{ e.name }}</span>
							</el-menu-item>
						</template>
						<el-menu-item @click="cut_btn({ name: '其他', id: 0 })" class="sub-menu" index="1000">
							<el-icon><icon-menu /></el-icon>
							<span>其他</span>
						</el-menu-item>
					</el-menu>
				</div>
				<div class="home_bot_r">
					<p>{{ journal_text }}</p>
					<div class="bot_r_box1">
						<div @click="item_btn(e)" class="item" v-for="(e, i) in journal_list_data.list">
							<div>
								<img :src="e.image" alt="" />
							</div>
							<p>{{ e.title }}</p>
						</div>
						<div v-if="journal_list_data.total != 0" style="width: 190px" v-for="(e, i) in 5"></div>
						<el-empty v-if="journal_list_data.total == 0" style="margin: auto; margin-top: 80px" description="暂无数据"></el-empty>
					</div>
					<div v-if="journal_list_data.total != 0 && !(journal_list_data.total <= 8)" class="bot_r_pagination">
						<el-pagination @current-change="current_change" background layout="prev, pager, next" :total="journal_list_data.total" :page-size="8"> </el-pagination>
					</div>
				</div>
			</div>
			<div v-else class="home_bot_one">
				<div class="home_bot_r">
					<div class="bot_r_box1 bot_r_box1_1">
						<div @click="item_btn(e)" class="item item1" v-for="(e, i) in journal_list_data.list">
							<div>
								<img :src="e.image" alt="" />
							</div>
							<p>{{ e.title }}</p>
						</div>
						<div v-if="journal_list_data.total != 0" style="width: 190px" v-for="(e, i) in 5"></div>
						<el-empty v-if="journal_list_data.total == 0" style="margin: auto; margin-top: 80px" description="暂无数据"></el-empty>
					</div>
					<div v-if="journal_list_data.total != 0 && !(journal_list_data.total <= 8)" style="justify-content: center" class="bot_r_pagination">
						<el-pagination @current-change="current_change" background layout="prev, pager, next" :total="journal_list_data.total" :page-size="8"> </el-pagination>
					</div>
				</div>
			</div>
		</div>
		<el-dialog
			v-model="permissiondialogVisible"
			title=""
			top="20vh"
			width="500px"
			custom-class="dialog_class"
			:before-close="
				() => {
					permissiondialogVisible = false;
				}
			"
		>
			<div v-if="common_init" class="permission_dialog_box">
				<img class="info_img" src="../../assets/info.svg" alt="" />
				<p class="permission_p">非常抱歉，该账号没有权限查看，如有需求请联系我们</p>
				<div class="permission_div1">
					<img src="../../assets/phone.svg" alt="" />
					<span>{{ common_init.config.info.phone }}（ {{ common_init.config.info.user_name }} ）</span>
				</div>
				<div class="permission_div2">
					<el-button @click="permissiondialogVisible = false">我知道了</el-button>
					<!-- <el-button type="primary">立即入会</el-button> -->
				</div>
			</div>
		</el-dialog>
		<el-dialog
			v-model="logindialogVisible"
			title=""
			top="20vh"
			width="500px"
			custom-class="dialog_class"
			:close-on-click-modal="journal_list_data ? true : false"
			:close-on-press-escape="journal_list_data ? true : false"
			:show-close="journal_list_data ? true : false"
			:before-close="
				() => {
					logindialogVisible = false;
				}
			"
		>
			<div v-if="common_init" class="permission_dialog_box">
				<img class="info_img" src="../../assets/info.svg" alt="" />
				<p class="permission_p">需要登录后才能查看</p>
				<!-- <div class="permission_div1">
					<img src="../../assets/phone.svg" alt="" />
					<span>{{ common_init.config.info.phone }}（ {{ common_init.config.info.user_name }} ）</span>
				</div> -->
				<div class="permission_div2">
					<el-button v-if="journal_list_data" @click="logindialogVisible = false">我知道了</el-button>
					<el-button @click="login_immediately" type="primary">立即登录</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			journal_name: '',
			journal_folder: [],
			journal_list_data: '',
			page: 1,
			type: -1,
			journal_text: '全部',
			check_root: '',
			permissiondialogVisible: false,
			logindialogVisible: false,
			common_init: '',
			user_record_type: '',
			img_url: '',
		};
	},
	created() {
		// document.title = '';
	},
	mounted() {
		this.get_journal_check_root();
		this.get_journal_folder();
		this.get_journal_list();
		this.get_common_init();
		if (localStorage.token) {
			this.get_user_record();
		}
	},
	methods: {
		login_immediately() {
			if (window.parent) {
				window.parent.postMessage(
					{
						cmd: 'login',
						params: {
							status: '0',
						},
					},
					'*'
				);
			}
		},
		item_btn(e) {
			let routeUrl = this.$router.resolve({ path: '/journalPreview', query: { store_id: localStorage.store_id, id: e.id, label: 'periodica' } });
			if (this.check_root.show_type == 1) {
				if (localStorage.is_pc == 1) {
					window.open(routeUrl.href, '_blank');
				} else {
					if (window.parent) {
						window.parent.location.href = routeUrl.href;
					} else {
						window.location.href = routeUrl.href;
					}
				}
			} else if (this.check_root.show_type == 2) {
				if (localStorage.token) {
					if (localStorage.is_pc == 1) {
						window.open(routeUrl.href, '_blank');
					} else {
						if (window.parent) {
							window.parent.location.href = routeUrl.href;
						} else {
							window.location.href = routeUrl.href;
						}
					}
				} else {
					this.logindialogVisible = true;
				}
			} else if (this.check_root.show_type == 3) {
				if (localStorage.token) {
					if (this.user_record_type) {
						if (localStorage.is_pc == 1) {
							window.open(routeUrl.href, '_blank');
						} else {
							if (window.parent) {
								window.parent.location.href = routeUrl.href;
							} else {
								window.location.href = routeUrl.href;
							}
						}
					} else {
						this.permissiondialogVisible = true;
					}
				} else {
					this.logindialogVisible = true;
				}
			}
		},
		search_btn() {
			this.page = 1;
			this.type = 0;
			this.get_journal_list();
		},
		cut_btn(e) {
			event.stopPropagation();
			this.journal_text = e.name;
			this.journal_name = '';
			this.page = 1;
			this.type = e.id;
			this.get_journal_list();
		},
		current_change(e) {
			this.page = e;
			this.get_journal_list();
		},
		async get_journal_list() {
			const res = await this.$request(
				'/api/pc/file',
				{
					page: this.page,
					type: this.type,
					search: this.journal_name,
					limit: 8,
					label: 'periodica',
				},
				'get'
			);
			if (res.code == 1) {
				this.journal_list_data = res.data;
			} else {
				if (localStorage.token) {
					this.permissiondialogVisible = true;
				} else {
					this.logindialogVisible = true;
				}
			}
		},
		async get_journal_folder() {
			try {
				const res = await this.$request('/api/pc/file/folder', { label: 'periodica' }, 'get');
				this.journal_folder = res.data;
			} catch (error) {}
		},
		async get_journal_check_root() {
			const res = await this.$request(
				'/api/pc/file/check_root',
				{
					label: 'periodica',
				},
				'get'
			);
			this.check_root = res.data;
			if (this.check_root.file) {
				this.img_url = this.check_root.file;
			} else {
				this.img_url = '../../assets/bg.svg';
			}
		},
		async get_common_init() {
			const res = await this.$request('/api/common/init', {}, 'get');
			this.common_init = res.data;
		},
		async get_user_record() {
			try {
				const res = await this.$request('/api/pc/user/record', {}, 'get');
				if (res.code != 0) {
					this.user_record_type = res.data.type;
				}
			} catch (error) {}
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .el-input--large .el-input__inner {
	border-radius: 4px 0px 0px 4px !important;
}

::v-deep .el-menu-item-group__title {
	padding: 0 0 0 20px;
}

::v-deep .el-sub-menu__title {
	padding: 0 !important;
	padding-left: 0 !important;
}

::v-deep .el-sub-menu .el-menu-item {
	min-width: 100px;
}

.permission_dialog_box {
	width: 100%;
	text-align: center;

	.info_img {
		width: 38px;
		height: 38px;
		margin: 20px;
	}

	.permission_p {
		font-size: 15px;
		font-weight: 400;
		color: #303133;
	}

	.permission_div1 {
		margin: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 16px;
			height: 16px;
			margin: 0 10px;
		}

		span {
			font-size: 14px;
			font-weight: 400;
			color: #2d91e3;
		}
	}

	.permission_div2 {
		margin-top: 30px;
	}
}

.home {
	width: 100%;
	background: rgba(248, 248, 248, 0.39);
	min-height: 100vh;

	.home_top {
		width: 100%;

		// min-width: 1440px;
		.home_top_img {
			width: 100%;
			height: 300px;
			border: none;
			object-fit: cover;
		}

		.home_top_search {
			width: 600px;
			margin: auto;
			margin-top: -260px;

			.home_top_p_div {
				width: 100%;
				height: 130px;
				display: flex;
				align-items: center;
				color: #ffffff;
				justify-content: center;
				font-size: 42px;
			}

			.home_top_search_box {
				.home_top_search_box_div {
					display: flex;
					align-items: center;

					.el-button {
						width: 125px;
						height: 40px;
						background: linear-gradient(131deg, #66a7ff 0%, #197aff 100%);
						border-radius: 0px 4px 4px 0px;
					}
				}
			}
		}
	}

	.home_bot {
		max-width: 1080px;
		// height: calc(100vh - 270px);
		overflow: auto;
		margin: 55px auto;
		margin-bottom: 0;
		background: #ffffff;

		.home_bot_one {
			width: 100%;
			display: flex;

			.home_bot_l {
				min-width: 180px;

				.sub-menu {
					padding: 0 !important;
					padding-left: 0 !important;
				}
			}

			.home_bot_r {
				width: 100px;
				flex-grow: 1;
				background: #ffffff;

				> p {
					font-size: 16px;
					font-weight: bold;
					color: #303133;
					padding: 15px 30px;
					border-right: 1px solid #ebeef5;
					border-bottom: 1px solid #ebeef5;
				}

				.bot_r_box1 {
					width: 100%;
					// min-height: 600px;
					padding: 30px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.item {
						width: 190px;
						height: 250px;
						background: rgba(0, 0, 0, 0);
						border: 1px solid #d5d5d5;
						margin-bottom: 20px;
						position: relative;
						cursor: pointer;

						div {
							width: 100%;
							height: 100%;

							img {
								width: 100%;
								height: 100%;
							}
						}

						p {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							text-align: center;
							height: 36px;
							line-height: 36px;
							background: rgba(0, 0, 0, 0.45);
							font-size: 13px;
							font-weight: 400;
							color: #ffffff;
						}
					}

					.item:hover p {
						transition: all 0.5s;
						background: #197aff;
					}
				}

				.bot_r_pagination {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					padding: 0 30px;
					margin-bottom: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.home {
		.home_top {
			min-width: 300px;

			.home_top_img {
				height: 180px;
			}

			.home_top_search {
				width: 80%;
				margin-top: -370px;

				.home_top_p_div {
					height: 200px;
					font-size: 32px;
					padding-top: 60px;
				}

				.home_top_search_box {
					margin-top: -20px;
				}
			}
		}

		.home_bot {
			width: 100%;
			min-width: 300px;
			margin-top: 30px;

			.home_bot_one {
				.home_bot_l {
					width: 0px !important;
					min-width: 110px !important;

					.el-menu {
						width: 110px !important;
					}
				}

				.home_bot_r {
					position: relative;

					.bot_r_box1 {
						min-height: 300px;
						padding: 20px 10px;
						height: calc(100vh - 235px);
						overflow: auto;
						box-sizing: border-box;
						padding-bottom: 80px;

						.item {
							margin: 20px auto;
							margin-top: 0;
						}

						.item1 {
							width: 45%;
							height: 200px;
						}
					}

					.bot_r_box1_1 {
						height: calc(100vh - 180px);
					}

					.bot_r_pagination {
						background: #ffffff;
						position: absolute;
						bottom: -20px;
						padding: 5px;
						padding-bottom: 20px;
					}
				}
			}
		}
	}
}
</style>
<style>
.dialog_class {
	width: 80% !important;
	max-width: 500px !important;
}
</style>
